.hero_block {
	@extend %global_flex_block_row_wrap_flex-start_stretch;

	width: 100%;
}

.hero_block_col {
	@include bp($point_2, min) {
		width: 50%;
	}

	@include bp($point_3) {
		width: 100%;

		&:first-child {
			order: 2;
		}

		&:last-child {
			order: 1;
		}
	}
}

.hero_nav_w {
	height: 100%;

	@include bp($point_2, min) {
		padding: 5rem 9.2rem 6.4rem 0;
	}

	@include bp($point_3) {
		padding: 3rem 0 6rem;
	}
}

.hero_nav {
	width: 100%;
	margin: 0 0 2.6rem;
	padding: 0;

	list-style: none;

	&:last-child {
		margin-bottom: 0;
	}
}

.hero_nav_item {
	@extend %global_flex_block_row_wrap_flex-start_center;

	position: relative;

	width: 100%;

	padding: 1rem 2rem 1rem 0;

	border-bottom: 1px solid #d2d2d2;

	@include bp($point_2, min) {
		min-height: 8.8rem;
	}

	&:first-child {
		border-top: 1px solid #d2d2d2;
	}
}

.hero_nav_link {
	@extend %transition_color;

	font-weight: 600;

	color: $color_text_base;

	&:hover, &:focus, &:active {
		text-decoration: none;
		color: $blue;
	}

	@include bp($point_2 + 1, min) {
		font-size: 3rem;
	}

	@media (min-width: $point_4) and (max-width: $point_2) {
		font-size: 2rem;
	}

	@include bp($point_4 - 1) {
		font-size: 1.6rem;
	}
}

.hero_nav_arrow {
	position: absolute;
	top: 50%;
	right: 0;

	width: 1rem;
	height: 2rem;
	margin-top: -1rem;

	color: $color_text_base;
}

.single_hero_link_w {
	width: 100%;
}

.single_hero_link_item {
	width: 100%;

	margin-bottom: 3rem;

	&:last-child {
		margin-bottom: 0;
	}
}

.single_hero_link {
	position: relative;

	padding: 0 4.4rem 1.6rem 0;

	font-weight: 600;

	color: $black;

	@include bp($point_2 + 1, min) {
		font-size: 3rem;
	}

	@media (min-width: $point_4) and (max-width: $point_2) {
		font-size: 2rem;
	}

	@include bp($point_4 - 1) {
		font-size: 1.6rem;
	}

	&:before {
		position: absolute;
		left: 0;
		bottom: 0;

		content: '';

		width: 100%;
		height: 1.3rem;

		background-color: $blue;
	}

	&:hover, &:focus, &:active {
		text-decoration: none;
	}
}

.hero_block_img_w {
	@extend %global_flex_block_row_wrap_flex-start_flex-end;

	position: relative;

	width: 100%;
	height: 100%;

	@include bp($point_2 + 1, min) {
		min-height: 52.4rem;
		padding: 8rem 0 8rem 6.8rem;
	}

	@media (min-width: $point_3) and (max-width: $point_2) {
		padding: 3rem;
	}

	@include bp($point_3) {
		padding: 3rem 0;
	}

	@media (min-width: $point_4) and (max-width: $point_2) {
		min-height: 50vw;
	}

	@include bp($point_4 - 1) {
		min-height: calc(var(--full-screen) - 6rem);
	}
}

.hero_block_img {
	z-index: 1;

	position: absolute;
	top: 0;

	height: 100%;

	@include bp($point_2, min) {

		left: 0;
		width: 50vw;
	}

	@include bp($point_3) {
		left: (-$gl_indent);
		right: (-$gl_indent);
	}

	&:before {
		position: absolute;
		top: 0;
		left: 0;

		content: '';

		width: 100%;
		height: 100%;

		background-color: #292929;

		opacity: .5;
	}
}

.hero_block_content {
	z-index: 2;

	position: relative;

	width: 100%;
	max-width: 69rem;
}