//---------------------------------------------fonts
$font_path: "../fonts/";
$font_1: 'Montserrat', sans-serif;
$font_size_base: 1.6rem;
//---------------------------------------------fonts###

//---------------------------------------------colors
$white: #fff;
$black: #313131;

$blue: #2a63af;
$gray: #f2f2f2;
$gray_2: rgba(42, 99, 175, .1);

$color_text_base: $black;
$color_link_base:#4aa3df;
$body_bg: $white;
//---------------------------------------------colors###

//---------------------------------------------z-index
$z-index: (
	popap : 9999,
	header : 100,
	footer : 100
);
//---------------------------------------------z-index###

//---------------------------------------------layout
$height_footer: 21rem;
$height_header: 8rem;
$page_width: 151.8rem;

//---------------------------------------------layout###

//---------------------------------------------global values
$gl_indent: 2rem;
$gl_radius: 5rem;
//---------------------------------------------global values###

//---------------------------------------------media points
$point_1: 1200px;
$point_2: 1024px;
$point_3: 1023px;
$point_4: 768px;
$point_5: 640px;
$point_6: 480px;
$point_7: 375px;
$point_8: 320px;
//---------------------------------------------media points

//---------------------------------------------forms
$input_height: 5.4rem;
$input_offset: 0 2rem;
$input_font_size: $font_size_base;
$input_border_color: #DFDFDF;
$input_border_color_active: $blue;
$input_placeholder_color: $black;
$input_text_color: $blue;
$input_radius: $gl_radius;
//---------------------------------------------forms###