.select2-selection--single {
	background-color: transparent;
	border: 2px solid #DFDFDF;
	border-radius: 5rem;

	.select2-selection__rendered {
		color: #2A63AF;
		font-weight: 500;
		font-size: 1.8rem;
		line-height: 5.4rem;
		text-align: center;
	}

	.select2-selection__clear {
		cursor: pointer;
		float: right;
		font-weight: bold;
	}

	.select2-selection__placeholder {
		color: #999;
	}

	.select2-selection__arrow {
		position: absolute;
		top: 50%;
		right: 2rem;

		width: 2.1rem;
		height: 1rem;
		margin-top: -.5rem;

		b {
			position: absolute;
			top: 0;
			left: 0;

			width: 100%;
			height: 100%;

			background-image: url("../i/icons/arrow_d.svg");
			background-position: 50% 50%;
			background-repeat: no-repeat;
			background-size: cover;
		}
	}
}

&[dir="rtl"] {
	.select2-selection--single {
		.select2-selection__clear {
			float: left;
		}

		.select2-selection__arrow {
			left: 1px;
			right: auto;
		}
	}
}

&.select2-container--disabled {
	.select2-selection--single {
		background-color: #eee;
		cursor: default;

		.select2-selection__clear {
			display: none;
		}
	}
}

&.select2-container--open {
	.select2-selection--single {
		.select2-selection__arrow {
			b {
				border-color: transparent transparent #888 transparent;
				border-width: 0 4px 5px 4px;
			}
		}
	}
}
