.select2-selection--multiple {
	background-color: white;
	border: 1px solid #aaa;
	border-radius: 4px;
	cursor: text;

	.select2-selection__rendered {
		box-sizing: border-box;
		list-style: none;
		margin: 0;
		padding: 0 5px;
		width: 100%;

		li {
			list-style: none;
		}
	}

	.select2-selection__placeholder {
		color: #999;

		margin-top: 5px;

		float: left;
	}

	.select2-selection__clear {
		cursor: pointer;
		float: right;
		font-weight: bold;
		margin-top: 5px;
		margin-right: 10px;
	}

	.select2-selection__choice {
		background-color: #e4e4e4;

		border: 1px solid #aaa;
		border-radius: 4px;
		cursor: default;

		float: left;

		margin-right: 5px;
		margin-top: 5px;
		padding: 0 5px;
	}

	.select2-selection__choice__remove {
		color: #999;
		cursor: pointer;

		display: inline-block;
		font-weight: bold;

		margin-right: 2px;

		&:hover {
			color: #333;
		}
	}
}

&[dir="rtl"] {
	.select2-selection--multiple {
		.select2-selection__choice, .select2-selection__placeholder, .select2-search--inline {
			float: right;
		}

		.select2-selection__choice {
			margin-left: 5px;
			margin-right: auto;
		}

		.select2-selection__choice__remove {
			margin-left: 2px;
			margin-right: auto;
		}
	}
}

&.select2-container--focus {
	.select2-selection--multiple {
		border: solid black 1px;
		outline: 0;
	}
}

&.select2-container--disabled {
	.select2-selection--multiple {
		background-color: #eee;
		cursor: default;
	}

	.select2-selection__choice__remove {
		display: none;
	}
}