//------------------------------------------------------------base styles
:root {
	--full-screen: calc(var(--vh, 1vh) * 100);

}

@include bp($point_2 + 1, min) {
	::-webkit-scrollbar {
		width: 6px;
	}

	::-webkit-scrollbar-track {
		-webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.3);
	}

	::-webkit-scrollbar-thumb {
		-webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.5);
	}
}

html {
	height: 100%;
	font: 400 10px/1.33 $font_1;

	@include bp($point_2 + 1, min) {
		scrollbar-color: rgba(0,0,0,0.5) rgba(0,0,0,0.3);
		scrollbar-width: thin;
	}
}

body {
	position: relative;

	display: flex;
	height: 100%;

	font-size: $font_size_base;
	color: $color_text_base;

	-webkit-text-size-adjust: none;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;

	overflow-x: hidden;

	background: $body_bg;

	@supports (display: grid) {
		display: block;
	}

	&.menu_open {
		overflow: hidden;
	}

}

.wrapper {
	position: relative;

	width: 100%;
	display: flex;
	flex-direction: column;
	min-height: 100%;
	height: auto !important;

	overflow-x: hidden;
}

.base {
	width: 100%;
	position: relative;

	@include bp($point_2 + 1, min) {
		padding: $height_header 0 0;
	}

	@include bp($point_2) {
		padding: 6rem 0 0;
	}

	flex: 1 0 auto;
}

a {
	text-decoration: none;
	color: $color_link_base;

	&:hover,
	&:focus {
		text-decoration: underline;
	}

}

.elements_list {
	padding: 3rem;
}

//------------------------------------------------------------base styles###
