.footer {
	width: 100%;
	min-height: $height_footer;
	padding: 4.6rem $gl_indent;

	flex: 0 0 auto;

	font-size: 1rem;

	background-color: $blue;

	will-change: transform;
}

.footer_col_w {
	@extend %global_flex_block_row_wrap_flex-start;

	width: 100%;

	@include bp($point_1 + 1, min) {
		margin-bottom: 7.4rem;
	}

	@include bp($point_1) {
		margin-bottom: 2rem;
	}

	&:last-child {
		margin-bottom: 0;
	}
}

.footer_col {
	@include bp($point_1 + 1, min) {
		width: 50%;
	}

	@include bp($point_1) {
		width: 100%;

		text-align: center;
		margin-bottom: 2rem;

		&:first-child {
			.footer_col_w:first-child & {
				display: none;
			}
		}

		&:last-child {
			margin-bottom: 0;
		}
	}
}

.footer_col_in {
	@extend %global_flex_block_row_wrap_flex-start;

	&.center_mod {
		@include bp(1460px, min) {
			align-items: center;
			align-content: center;
		}

		@include bp(1459px) {
			display: block;
		}
	}
}

.footer_nav {
	margin-right: 2rem;

	&:last-child {
		margin-right: 0;
	}

	@include bp($point_1) {
		.footer_col_w:first-child & {
			display: none;
		}
	}
}

.footer_social {
	@extend %global_flex_block_row_wrap_flex-start_center;

	@include bp($point_1 + 1, min) {
		margin-left: auto;
	}

	@include bp($point_1) {
		margin: 0 auto;
	}
}

.footer_social_title {
	margin-right: 1.8rem;

	font-weight: 500;
	font-size: 1.6rem;
	color: $white;

	@include bp($point_1) {
		display: none;
	}
}

.social_list {
	@extend %global_flex_block_row_wrap_flex-start_center;

	margin: 0 -3rem -2rem 0;
	padding: 0;

	list-style: none;
}

.social_item {
	padding: 0 3rem 2rem 0;
}

.social_link {
	@extend %transition_opacity;

	text-decoration: none;

	color: $white;

	&:hover, &:focus, &:active {
		text-decoration: none;
		opacity: .8;
	}
}

.footer_dev_info {
	@extend %global_flex_block_row_wrap_flex-start_center;

	@include bp(1460px, min) {
		margin-left: auto;
	}

	@include bp(1459px) {
		margin-top: 3rem;
	}

	@include bp($point_1) {
		display: none;
	}
}

.footer_dev_icon_w {
	display: block;
	width: 3rem;
	height: 3rem;
	margin-right: 1.4rem;

	color: $white;
}

.footer_dev_info_link {
	font-weight: 500;
	font-size: 1.3rem;
	color: $white;
}

.footer_copy {
	width: 100%;

	font-weight: 500;
	font-size: 1.3rem;
	color: $white;

	@include bp($point_1 + 1, min) {
		padding-right: 2rem;
	}
}