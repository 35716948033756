.icon {
	display: inline-block;
	width: 1em;
	height: 1em;
	fill: currentColor;
}

.icon-arrow_d {
	font-size:(13/10)*1rem;
	width:(23/13)*1em;
}
.icon-arrow_r {
	font-size:(20/10)*1rem;
	width:(10/20)*1em;
}
.icon-facebook {
	font-size:(28/10)*1rem;
	width:(16/28)*1em;
}
.icon-ilab {
	font-size:(30/10)*1rem;
	width:(30/30)*1em;
}
.icon-instagram {
	font-size:(28/10)*1rem;
	width:(24/28)*1em;
}
.icon-twitter {
	font-size:(28/10)*1rem;
	width:(26/28)*1em;
}
.icon-youtube {
	font-size:(32/10)*1rem;
	width:(32/32)*1em;
}
