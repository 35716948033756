//images
.inner_img {
	display: block;
	max-width: 100%;
	max-height: 100%;
}

.cover_img {
	display: block;
	width: 100%;
	height: 100%;

	object-fit: cover;
}

//buttons
.btn_base {
	@extend %gray_btn_1;
	@extend %btn_size_1;
}

.btn_base_2 {
	@extend %blue_btn_1;
	@extend %btn_size_2;
}