//---------------------------------------------sections
.section {
	position: relative;

	width: 100%;

	@include bp($point_4, min) {
		padding: 10rem $gl_indent;
	}

	@include bp($point_4 - 1) {
		padding: 6rem $gl_indent;
	}

	font-size: 1rem;

	&.bg_mod {
		background-color: $gray;
	}

	&.offset_mod {
		padding-top: 0;
		padding-bottom: 0;
	}

	&.bottom_offset_mod {
		@include bp($point_1 + 1, min) {
			padding-bottom: 24rem;
		}
	}

	&.bottom_offset_2_mod {
		padding-bottom: 0;
	}
}

.section_in {
	max-width: $page_width;
	width: 100%;
	margin: 0 auto;
}

.section_title {
	width: 100%;

	font-weight: 700;

	@include bp($point_2, min) {
		font-size: 4.6rem;
		line-height: (5.6/4.6);
	}

	@include bp($point_3) {
		font-size: 2.6rem;
		line-height: 1.2;
	}

	&.color_w_mod {
		color: $white;
	}

	&.h1_mod {
		@include bp($point_2 + 1, min) {
			font-size: 7.2rem;
			line-height: (8.8/7.2);
		}

		@include bp($point_2) {
			font-size: 4.2rem;
			line-height: 1.2;
		}
	}

	&.banner_mod {
		font-weight: 600;
	}
}

.section_descr {
	width: 100%;

	font-weight: 400;
	line-height: 1.76;

	@include bp($point_2 + 1, min) {
		font-size: 1.8rem;
	}

	@include bp($point_2) {
		font-size: 1.6rem;
	}

	&.color_w_mod {
		color: $white;
	}

	&.size_mod {
		font-weight: 600;
		@include bp($point_2 + 1, min) {
			font-size: 2.2rem;
			line-height: (2.7/2.2);
		}

		@include bp($point_2) {
			font-size: 1.8rem;
			line-height: 1.2;
		}
	}

	&.offset_mod {
		margin-bottom: 6.8rem;
	}

	p {
		margin-bottom: 2.8rem;

		&:last-child {
			margin-bottom: 0;
		}
	}
}

.section_head {
	width: 100%;
	max-width: 80rem;
	margin: 0  auto 5rem;

	text-align: center;

	&:last-child {
		margin-bottom: 0;
	}
}