//------------------------------------------------------------layout
.header {
	position: fixed;
	top: 0;
	left: 0;

	width: 100%;
	padding: 0 $gl_indent;

	font-size: 10px;

	background-color: $blue;

	will-change: transform;

	@include z-index(header);

	@include bp($point_2 + 1, min) {
		height: $height_header;
	}

	@include bp($point_2) {
		height: 6rem;
	}

	@include bp($point_1) {
		&:before {
			z-index: 1;

			position: fixed;
			top: 0;
			left: 0;

			content: '';

			width: 100%;
			height: var(--full-screen);

			background-color: #000;

			opacity: 0;
			pointer-events: none;
			transition: opacity .3s ease;
			will-change: opacity;

			body.menu_open & {
				z-index: 90;

				opacity: .8;
				pointer-events: auto;
			}
		}
	}
}

.header_in {
	@extend %global_flex_block_row_wrap_flex-start_center;

	height: 100%;
}

.header_logo {
	@extend %transition_opacity;

	display: block;
	@include bp($point_2 + 1, min) {
		width: 13rem;
	}

	@include bp($point_2) {
		width: 8rem;
	}
}

.header_nav {
	@include bp($point_1 + 1, min) {
		margin-left: auto;
	}

	@include bp($point_1) {
		z-index: 100;

		position: fixed;
		top: 0;
		right: 0;

		height: var(--full-screen);
		max-height: var(--full-screen);
		overflow-x: hidden;
		overflow-y: auto;

		background-color: $blue;
		transform: translateX(100%);
		//opacity: 0;
		pointer-events: none;

		transition: transform .3s ease;
		will-change: transform;

		body.menu_open & {
			transform: translateX(0);
			//opacity: 1;
			pointer-events: auto;
		}
	}

	@media (min-width: $point_2 + 1) and (max-width: $point_1) {
		padding: ($height_header + 4rem) $gl_indent $height_header;
	}

	@include bp($point_2) {
		padding: 10rem $gl_indent $height_header;
	}

	@media (min-width: $point_6) and (max-width: $point_1) {
		width: 32rem;
	}

	@include bp($point_6 - 1) {
		width: 100%;
	}
}

.main_nav_list {
	padding: 0;

	list-style: none;

	@include bp($point_1 + 1, min) {
		@include flex_block(row, wrap, flex-start, center, center);

		margin: 0 -6.2rem -3rem 0;

		&.v2_nod {
			margin: 0 -3.2rem -3rem 0;
		}
	}
}

.main_nav_item {
	@include bp($point_1 + 1, min) {
		padding: 0 6.2rem 3rem 0;

		.main_nav_list.v2_nod & {
			padding: 0 3.2rem 3rem 0;
		}
	}

	@include bp($point_1) {
		margin-bottom: 2rem;

		&:last-child {
			margin-bottom: 0;
		}
	}
}

.main_nav_link {
	text-decoration: none;
	font-weight: 500;
	font-size: 1.6rem;
	color: $white;

	.main_nav_list.v3_nod & {
		font-size: 1.3rem;
	}
}
//--------------------------------------------------------
//---------------------------------------------trigger
@mixin user_menu_butt_line {
	position: absolute;
	top: 50%;
	left: 0;
	width: 100%;
	height: .4rem;
	margin-top: -.2rem;
	background: $white;
}

.menu_trigger {
	z-index: 210;
	position: absolute;
	top: 50%;
	right: $gl_indent;
	display: none;
	height: 2.6rem;
	width: 3.8rem;
	margin-top: -1.6rem;
	text-transform: uppercase;

	color: transparent;
	cursor: pointer;

	@include bp($point_1) {
		display: block;
	}

	&:before {
		content: '';
		transform: translate(0, -1rem);
		transition: all .3s ease;

		@include user_menu_butt_line;
	}
	&:after {
		content: '';
		transform: translate(0, 1rem);
		transition: all .3s ease;

		@include user_menu_butt_line;
	}
	&.active_mod {
		&:before {
			transform: (rotate(45deg) translate(0, 0));
		}

		&:after {
			transform: (rotate(-45deg) translate(0, 0));
		}
	}
}

.menu_trigger_decor {
	transition: all .3s ease;

	.active_mod & {
		opacity: 0;
		transform: translate(100%, 0);
	}

	@include user_menu_butt_line;
}

//---------------------------------------------trigger###