.section_banner {
	position: relative;

	@include bp($point_1 + 1, min) {
		width: 100%;
		min-height: 74rem;
		margin-top: -24rem;
		padding: 9rem;
	}

	@include bp($point_2, min) {
		@include flex_block(row, wrap, flex-start);
	}

	@media (min-width: $point_4) and (max-width: $point_1) {
		margin: -10rem (-$gl_indent);
		padding: 10rem $gl_indent;
	}

	@include bp($point_4 - 1) {
		margin: -6rem (-$gl_indent);
		padding: 6rem $gl_indent;
	}
}

.section_bg {
	position: absolute;
	top: 0;
	left: 0;

	width: 100%;
	height: 100%;
}

.section_banner_col {
	position: relative;

	@include bp($point_2, min) {
		width: 50%;
	}

	@include bp($point_3) {
		margin-bottom: 3rem;

		&:last-child {
			margin-bottom: 0;
		}
	}
}

.section_banner_title_w {
	width: 100%;
	max-width: 50rem;
}