@mixin st_btn() {
	display: inline-flex;
	justify-content: center;
	align-items: center;
	
	text-align: center;
	white-space: nowrap;

	cursor: pointer;

	&:hover, &:focus {
		text-decoration: none;
	}

}

//color extends
%gray_btn_1 {
	font-weight: 500;
	color: $blue;

	background: #fff0;
	transition: color .3s ease, background .3s ease;
	border: 2px solid #DFDFDF;

	@include st_btn();

	&:hover {
		text-shadow: 0px 0px 1px $blue;
		background: #DFDFDF;
	}
}

%blue_btn_1 {
	font-weight: 500;
	color: $white;

	background: $blue;
	transition: color .3s ease, background .3s ease;

	@include st_btn();

	&:hover {
		text-shadow: 0px 0px 1px $white;
		background: lighten($blue, 10%);
	}
}

//size extends
%btn_size_1 {
	height: 5.3rem;
	padding: 0 4rem;

	line-height: 5.3rem;
	font-size: 1.2rem;

	border-radius: $gl_radius;
}

%btn_size_2 {
	height: 5.3rem;
	padding: 0 3rem;

	line-height: 5.3rem;
	font-size: 1.7rem;

	border-radius: $gl_radius;
}