.advantages_list_w {
	width: 100%;
	max-width: 117.2rem;
	margin: 0 auto;
}

.advantages_list {

	@include bp($point_4, min) {
		@include flex_block(row, wrap, flex-start, stretch);
	}

	@include bp($point_2, min) {
		margin: 0 -5rem -5rem 0;
	}

	@include bp($point_3) {
		margin: 0 -2rem -2rem 0;
	}
}

.advantages_item {
	@include bp($point_4, min) {
		width: 33.33%;
	}

	@include bp($point_2, min) {
		padding: 0 5rem 5rem 0;
	}

	@include bp($point_3) {
		padding: 0 2rem 2rem 0;
	}
}

.advantages_item_in {
	position: relative;

	width: 100%;
	height: 100%;

	background-color: $gray;

	@include bp($point_2, min) {
		min-height: 30.5em;
		padding: 14.2rem 3.3rem 3.3rem;
	}

	@media (min-width: $point_4) and (max-width: $point_3) {
		min-height: 22em;
	}

	@include bp($point_3) {
		padding: 12rem 2em 2rem;
	}
}

.advantages_info {
	@extend %global_flex_block_row_wrap_center_center;

	position: absolute;

	border-radius: 50%;

	background-color: $gray_2;

	@include bp($point_2, min) {
		top: 1.5rem;
		right: 1.5rem;
		width: 9.3rem;
		height: 9.3rem;
		padding: 1.2rem;
	}

	@include bp($point_3) {
		top: 3rem;
		left: 50%;

		width: 6rem;
		height: 6rem;
		margin-left: -3rem;
		padding: 4px;
	}
}

.advantages_img {
	@extend %global_flex_block_row_wrap_center_center;

	@include bp($point_2, min) {
		width: 5rem;
		height: 5rem;
	}

	@include bp($point_3) {
		width: 3rem;
		height: 3rem;
	}
}

.advantages_title {
	width: 100%;

	font-weight: 700;

	@include bp($point_2, min) {
		font-size: 2rem;
		line-height: (2.8/2);
	}

	@include bp($point_3) {
		font-size: 1.6rem;
		line-height: 1.2;
		text-align: center;
	}

	color: $black;
}

.advantages_count {
	width: 100%;

	font-style: normal;
	font-weight: 700;

	@include bp($point_2, min) {
		font-size: 4.2rem;
	}

	@include bp($point_3) {
		font-size: 1.8rem;
	}

	color: #2A63AF;
	text-align: center;
}