.calc_block {
	@extend %global_flex_block_row_wrap_flex-start;

	width: 100%;
}

.calc_block_col {
	&:first-child {
		@include bp($point_1, min) {
			width: 55.66%;
			margin-right: 2.2rem;
		}

		@include bp($point_1 - 1) {
			margin-bottom: 4rem;
		}
	}
	&:last-child {
		@include bp($point_1, min) {
			width: 42.16%;
			margin-left: auto;
		}
	}

	@include bp($point_1 - 1) {
		width: 100%;
	}
}

.calc_fields_list {
	@extend %global_flex_block_row_wrap_flex-start;

	margin: 0 -1rem -7rem 0;
}

.calc_field_item {

	@include bp($point_4, min) {
		width: 33.33%;
	}

	@media (min-width: $point_6 + 1) and (max-width: $point_4 - 1) {
		width: 50%;
	}

	@include bp($point_6) {
		width: 100%;
	}

	padding: 0 1rem 7rem 0;
}

.field_title {
	width: 100%;
	padding: 1rem 1rem 0;

	font-weight: 500;
	font-size: 1.2rem;
	line-height: (1.5/1.2);
	text-align: center;
	text-transform: uppercase;
	color: #313131;
}

.calc_value_block {
	width: 100%;
}

.calc_value_block_top {
	width: 100%;
	margin-bottom: 2rem;
	padding: 3.6rem 5.4rem;

	background-color: $blue;
}

.calc_value_list {
	display: flex;
	flex-flow: wrap row;
	justify-content: center;
	align-items: flex-end;
	align-content: flex-end;

	margin: 0 -8rem -4rem 0;
	padding: 0;
}

.calc_value_item {
	padding: 0 8rem 4rem 0;
}

.calc_value_item_in {
	width: 100%;
	text-align: center;
}

.calc_value_title {
	width: 100%;
	margin-bottom: 1.2rem;

	font-weight: 700;
	font-size: 2rem;
	line-height: (2.4/2);
	color: #fff;

	strong {
		font-size: 3.6rem;
	}

	span {
		font-size: 2.6rem;
	}
}

.calc_value_descr {
	width: 100%;

	font-weight: 500;
	font-size: 1.2rem;
	line-height: (1.5/1.2);
	text-transform: uppercase;

	color: #fff;
}

.calc_value_block_bottom {
	width: 100%;
	margin-bottom: 3rem;

	font-size: 1.2rem;
	line-height: (1.7/1.2);
	color: #7D7D7D;

	&:last-child {
		margin-bottom: 0;
	}

	p {
		margin-bottom: 2rem;

		&:last-child {
			margin-bottom: 0;
		}
	}

	ul, li {
		list-style: disc;
		margin-left: .8rem;
	}

	li {
		margin-bottom: 1rem;

		&:last-child {
			margin-bottom: 0;
		}
	}

}

.btn_w {
	@extend %global_flex_block_row_wrap_flex-start;

	margin: 0 -2rem -2rem 0;
	padding: 0;
}

.btn_item {
	padding: 0 2rem 2rem 0;
}